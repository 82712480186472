import * as React from "react";
import { PropertySpaceDetails as SharedUiPropertySpaceDetails } from "@shared-ui/lodging-property-offers";
import { PropertySpaces } from "@shared-ui/retail-lodging-property-spaces";
import { PropertyBexApiWrapper } from "components/shared/BexApiWrapper/PropertyBexApiWrapper";
import { ComponentProps } from "typings/flexFramework/FlexComponent";
import { IntersectionTracker } from "@shared-ui/clickstream-analytics-context";
import { LazyLoad } from "src/components/utility/LazyLoad/LazyLoad";

import { UitkSpacing } from "@egds/react-core/spacing";

const WrappedSpaceDetails = PropertyBexApiWrapper(SharedUiPropertySpaceDetails);
const WrappedPropertySpaces = PropertyBexApiWrapper(PropertySpaces);

export const PropertySpaceDetails: React.FC<ComponentProps> = ({ context }) => (
  <section>
    <UitkSpacing margin={{ blockstart: "six" }} padding="six">
      {context?.site?.brand === "vrbo" ? (
        <LazyLoad context={context}>
          <IntersectionTracker referrerId="HOT.HIS.PropertySpaceDetails" trackOnce linkName="Property Space Details">
            <WrappedPropertySpaces context={context} />
          </IntersectionTracker>
        </LazyLoad>
      ) : (
        <WrappedSpaceDetails context={context} />
      )}
    </UitkSpacing>
  </section>
);

export default PropertySpaceDetails;
